(function () {
  'use strict';

  /* @ngdoc object
   * @name discussions.index
   * @description
   *
   */
  angular
    .module('discussions.index', [
      'ui.router'
    ]);
}());
